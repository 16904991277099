import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static outlets = ['pages--product-component--product-component'];

  static values = {
    selectByOptionType: Boolean,
    translations: Object,
  };

  connect() {
    this.lastSnackbarParams = null;
    this.pagesProductComponentProductComponentOutlet.subscribeToVariantsSelected(
      this.variantSelected.bind(this),
    );
  }

  variantSelected(selectedVariants, impossibleSelection) {
    let firstSelectedVariant = selectedVariants[0];

    if (this.selectByOptionTypeValue) {
      let message;

      if (impossibleSelection) {
        message = this.translationsValue.impossibleSelection;
      } else if (firstSelectedVariant) {
        let availability = firstSelectedVariant.availability;
        if (availability === 'out_of_stock') {
          message = this.translationsValue.outOfStockSelection;
        } else if (['coming_soon', 'unavailable'].includes(availability)) {
          message = this.translationsValue.unavailableSelection;
        }
      }

      this.hideSnackbar('unorderableAlert');

      if (message) {
        this.lastSnackbarParams = {
          message: message,
          messageType: 'alert',
          uniqueKey: 'unorderableAlert',
          timeout: 2500,
        };
        this.showSnackbar(this.lastSnackbarParams);
      }
    }
  }

  showLastSnackbar() {
    this.showSnackbar(this.lastSnackbarParams);
  }
}
