import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['image', 'thumbnail'];

  static outlets = ['pages--product-component--product-component'];

  static values = {
    firstSelectedVariantId: String,
    isConfigurableProduct: Boolean,
  };

  SELECTED_THUMBNAIL_CLASS = 'ring-2';

  connect() {
    this.filterThumbnails(this.firstSelectedVariantIdValue);
    this.selectFirstAvailableImage();
    this.#productComponentOutlet.subscribeToVariantsSelected(
      this.variantSelected.bind(this),
    );
  }

  variantSelected(selectedVariants) {
    let firstSelectedVariantId = selectedVariants[0]?.id;
    if (firstSelectedVariantId) {
      this.filterThumbnails(firstSelectedVariantId);
      this.selectFirstAvailableImage();
    }
  }

  selectThumbnailImage({ currentTarget }) {
    const { imageId } = currentTarget.dataset;
    this.selectImage(imageId);
  }

  selectFirstAvailableImage() {
    const firstAvailableThumbnail = this.thumbnailTargets.find(
      ({ classList }) => !classList.contains('hidden'),
    );
    if (firstAvailableThumbnail) {
      this.selectImage(firstAvailableThumbnail.dataset.imageId);
    }
  }

  selectImage(imageId) {
    this.showSelectedImage(imageId);
    this.highlightSelectedThumbnail(imageId);
  }

  showSelectedImage(imageId) {
    this.imageTargets.forEach((target) => {
      const isSelected = target.dataset.imageId === imageId;
      target.classList.toggle('hidden', !isSelected);
    });
  }

  filterThumbnails(selectedVariantId) {
    this.thumbnailTargets.forEach((target) => {
      const { variantId, isMaster } = target.dataset;
      const showThumbnail =
        isMaster === 'true' ||
        variantId === selectedVariantId ||
        this.isConfigurableProductValue;

      target.classList.toggle('hidden', !showThumbnail);
    });
  }

  highlightSelectedThumbnail(imageId) {
    this.thumbnailTargets.forEach((target) => {
      const isSelected = target.dataset.imageId === imageId;
      target.classList.toggle(this.SELECTED_THUMBNAIL_CLASS, isSelected);
    });
  }

  get #productComponentOutlet() {
    return this.pagesProductComponentProductComponentOutlet;
  }
}
