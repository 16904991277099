import ApplicationController from '../../../../application_component/application_controller';
import OptionValueCollection from '../../option_value_collection';

export default class extends ApplicationController {
  static targets = ['optionValueInput', 'displayPrice'];

  static values = {
    optionValueIds: Array,
    optionTypeId: String,
    currency: String,
  };

  get optionTypeId() {
    return this.optionTypeIdValue;
  }

  get optionValueIds() {
    return new OptionValueCollection(this.optionValueIdsValue);
  }

  get selectedOptionValue() {
    return this.optionValueInputTargets.find((input) =>
      this.#isInputSelected(input),
    )?.value;
  }

  setAvailability({ optionValueId, available }) {
    const optionValueInput = this.optionValueInputTargets.find((input) => {
      return input.value === optionValueId;
    });

    if (!!optionValueInput) {
      optionValueInput.readOnly = !available;
    }
  }

  setPriceDifference({ optionValueId, priceDifference }) {
    this.displayPriceTargets.forEach((displayPriceTarget) => {
      if (displayPriceTarget.dataset.optionValueId === optionValueId) {
        displayPriceTarget.textContent = this.formatPrice(
          priceDifference,
          this.currencyValue,
        );
      }
    });
  }

  resetPrice(optionValueId) {
    this.setPriceDifference({ optionValueId, priceDifference: 0 });
  }

  #isInputDropdown(element) {
    return element.tagName === 'SELECT';
  }

  #isInputSelected(element) {
    return (
      (element.tagName === 'INPUT' && element.checked) ||
      this.#isInputDropdown(element)
    );
  }
}
