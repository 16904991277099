import Choices from 'choices.js';
import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['product', 'specs'];

  connect() {
    this.select = this.initializeSelector(this.productTarget);
  }

  disconnect() {
    if (this.select) this.select.destroy();
  }

  initializeSelector(element) {
    const select = new Choices(element, {
      classNames: {
        flippedState: 'select-one',
      },
      itemSelectText: '',
      shouldSort: false,
      allowHTML: true,
    });

    return select;
  }

  updateSpecs(event) {
    this.hideSpecs();
    this.showSpecs(event.target.value);

    const url = new URL(window.location.href);
    url.searchParams.set('slug', event.target.value);
    Turbo.navigator.history.push(url);
  }

  reloadPage() {
    window.location.reload();
  }

  hideSpecs() {
    this.specsTargets.forEach((spec) => spec.classList.add('hidden'));
  }

  showSpecs(slug) {
    const currentSpecs = this.specsTargets.find(
      (spec) => spec.dataset.slug === slug,
    );

    if (currentSpecs) {
      currentSpecs.classList.remove('hidden');
    }
  }
}
