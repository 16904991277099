export default class OptionValueCollection {
  static empty() {
    return new OptionValueCollection([]);
  }

  constructor(optionValueIds) {
    this.optionValueIds = optionValueIds;
  }

  forEach(...args) {
    return this.optionValueIds.forEach(...args);
  }

  toArray() {
    return this.optionValueIds;
  }

  push(...optionValueIds) {
    this.optionValueIds.push(...optionValueIds);
  }

  includes(optionValueId) {
    return this.optionValueIds.includes(optionValueId);
  }

  equals(otherOptionValueCollection) {
    return (
      this.toSet().difference(otherOptionValueCollection.toSet()).size == 0
    );
  }

  toSet() {
    return new Set(this.#asIntegerArray());
  }

  #asIntegerArray() {
    return this.optionValueIds.map((ov) => parseInt(ov));
  }
}
