import ApplicationController from '../../application_component/application_controller';
import Meta from '../../../assets/javascript/application/meta';
import OptionValueCollection from '../../containers/products/option_value_collection';
import Variant from './variant';

export default class extends ApplicationController {
  #variantIdsSelectedListeners = [];
  #impossibleSelection = false;

  static targets = [
    'addToBag',
    'description',
    'price',
    'strikethroughPrice',
    'outOfStock',
    'totalPrice',
    'variantDetail',
    'waitlist',
    'waitlistVariantInput',
    'impossible',
  ];

  static values = {
    currency: String,
    removeDecimals: Boolean,
    priceNotPresent: String,
    variantsDataMap: Object,
    selectedVariantIds: Array,
    variantIdOptionValuesLookup: Object,
  };

  connect() {
    const language = Meta.findByName('language');
    const country = Meta.findByName('country');
    this.locale = `${language}-${country}`;
    this.element.addEventListener('quantity-changed', () => {
      this.handleVariantSelection();
    });
    this.handleVariantSelection();
  }

  subscribeToVariantsSelected(callback) {
    this.#variantIdsSelectedListeners.push(callback);
    // The ordering controllers connect in can change,
    // so we can't garuntee subscriptions happy before
    // the first value change
    this.#triggerVariantIdsSelectedCallbacks();
  }

  setSelectedVariantIdsFromOptionValues(optionValues) {
    let variant = this.findVariantByOptionValues(optionValues);

    if (variant) {
      this.setSelectedVariantIds([variant.id]);
    } else {
      this.setSelectedVariantIds([], true);
    }
  }

  setSelectedVariantIds(variantIds, impossibleSelection = false) {
    this.#impossibleSelection = impossibleSelection;
    this.selectedVariantIdsValue = variantIds.map((id) => id.toString());
    this.handleVariantSelection();
    this.#triggerVariantIdsSelectedCallbacks();
  }

  handleVariantSelection() {
    const selectedVariants = this.#selectedVariants;

    if (selectedVariants.length > 1) {
      const price = this.#sumOfVariantPrices(selectedVariants);
      this.#updateTotalPrice(price);
    } else if (selectedVariants.length === 1) {
      const variant = selectedVariants[0];

      this.#updateUrl(variant.sku);
      this.#updateVariantActionComponents(variant.availability);
      this.#updateDetails(variant.id.toString());
      this.#updatePrice(variant.displayPrice);
      this.#updateStrikethroughPrice(variant.strikethroughPrice);
      this.#updateTotalPrice(variant.price);
      this.#updateDescription(variant.description);
      this.#updateWaitlistVariant(variant.id);
    } else {
      this.#updateTotalPrice(this.#priceWhenNoVariantSelected());
    }

    if (this.#impossibleSelection) {
      this.#updateVariantActionComponents('impossible');
    }
  }

  findVariantByOptionValues(optionValues) {
    return (
      this.#variants.find((variant) =>
        variant.optionValues.equals(optionValues),
      ) || null
    );
  }

  get #variants() {
    if (this.variants == undefined) {
      this.variants = Object.keys(this.variantsDataMapValue).map(
        (id) =>
          new Variant({
            id: id,
            ...this.variantsDataMapValue[id],
            optionValueMap: this.variantIdOptionValuesLookupValue[id],
          }),
      );
    }

    return this.variants;
  }

  get #selectedVariants() {
    return this.#variants.filter((variant) =>
      this.selectedVariantIdsValue.includes(variant.id.toString()),
    );
  }

  #triggerVariantIdsSelectedCallbacks() {
    this.#variantIdsSelectedListeners.forEach((callback) => {
      callback(this.#selectedVariants, this.#impossibleSelection);
    });
  }

  #sumOfVariantPrices(variants) {
    return variants.reduce((acc, variant) => {
      return acc + parseFloat(variant.price, 10);
    }, 0);
  }

  #priceWhenNoVariantSelected() {
    if (this.#oneVariantAvailable()) {
      return this.#firstVariantPrice();
    }
    return 0;
  }

  #firstVariantPrice() {
    return Object.values(this.variantsDataMapValue)[0].price;
  }

  #oneVariantAvailable() {
    return Object.values(this.variantsDataMapValue).length === 1;
  }

  #updateUrl(sku) {
    if (this.#oneVariantAvailable() == 1) {
      // For products with just a master variant, we don't want to add the variant sku to the url
      return;
    }
    const currentURL = new URL(window.location);
    currentURL.searchParams.set('v', sku);

    const variantLocation = currentURL.toString();
    window.history.replaceState({}, null, variantLocation);
  }

  #updateDescription(description) {
    if (!this.hasDescriptionTarget || !description) return;

    this.descriptionTarget.querySelector('.prose').innerHTML = description;
  }

  #updatePrice(price) {
    if (!this.hasPriceTarget) return;

    if (price) {
      this.priceTarget.innerText = price;
    } else {
      this.priceTarget.innerText = this.priceNotPresentValue;
    }
  }

  #updateStrikethroughPrice(strikethroughPrice) {
    if (!this.hasStrikethroughPriceTarget) return;

    this.strikethroughPriceTarget.innerText = strikethroughPrice;
    if (strikethroughPrice) {
      this.strikethroughPriceTarget.classList.add('mr-1.5');
    } else {
      this.strikethroughPriceTarget.classList.remove('mr-1.5');
    }
  }

  #updateWaitlistVariant(variantId) {
    if (!this.hasWaitlistVariantInputTarget) return;

    this.waitlistVariantInputTarget.value = variantId;
  }

  #updateVariantActionComponents(availability) {
    if (this.hasAddToBagTarget) {
      this.addToBagTarget.classList.toggle(
        'hidden',
        availability !== 'in_stock',
      );
    }

    if (this.hasWaitlistTarget) {
      this.waitlistTarget.classList.toggle(
        'hidden',
        !['coming_soon', 'unavailable'].includes(availability),
      );
    }

    if (this.hasOutOfStockTarget) {
      this.outOfStockTarget.classList.toggle(
        'hidden',
        availability !== 'out_of_stock',
      );
    }

    if (this.hasImpossibleTarget) {
      this.impossibleTarget.classList.toggle(
        'hidden',
        availability !== 'impossible',
      );
    }
  }

  #updateDetails(id) {
    this.variantDetailTargets.forEach((detail) => {
      detail.classList.add('hidden');
      if (detail.dataset.variantId === id) {
        detail.classList.remove('hidden');
      }
    });
  }

  #updateTotalPrice(price) {
    const multiplier = this.getJsTarget('quantity')?.value || 1;
    try {
      const total = parseFloat(price, 10) * parseInt(multiplier, 10);
      const formattedPrice = this.formatPrice(
        total,
        this.currencyValue,
        false,
        this.removeDecimalsValue,
      );
      this.totalPriceTarget.innerText = formattedPrice;
    } catch (ignore) {}
  }
}
