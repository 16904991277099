import OptionValueCollection from '../../containers/products/option_value_collection';

export default class Variant {
  constructor({
    id,
    availability,
    description,
    displayPrice,
    price,
    sku,
    strikethroughPrice,
    optionValueMap,
  } = {}) {
    optionValueMap = optionValueMap || {};

    this.id = id;
    this.availability = availability;
    this.description = description;
    this.displayPrice = displayPrice || null;
    this.price = !!displayPrice ? price : null;
    this.sku = sku;
    this.strikethroughPrice = strikethroughPrice;
    this.optionValues = new OptionValueCollection(
      Object.values(optionValueMap),
    );
    this.optionValuesMap = optionValueMap;
  }
}
