import ApplicationController from '../../../application_component/application_controller';
import OptionValueCollection from '../option_value_collection';

export default class extends ApplicationController {
  static outlets = [
    'pages--product-component--product-component',
    'containers--products--option-types-selector--option-values-component--option-values',
  ];

  static targets = ['variantId'];

  connect() {
    this.selectOptionValue();
    this.#productComponentOutlet.subscribeToVariantsSelected(
      this.variantSelected.bind(this),
    );
  }

  selectOptionValue() {
    this.#productComponentOutlet.setSelectedVariantIdsFromOptionValues(
      this.#selectedOptionValues(),
    );
  }

  variantSelected(selectedVariants) {
    const selectedVariant = selectedVariants[0] || null;

    this.variantIdTarget.value = selectedVariant?.id || null;

    this.#optionValuesOutlets.forEach((optionValuesOutlet) => {
      const optionTypeId = optionValuesOutlet.optionTypeId;

      optionValuesOutlet.optionValueIds.forEach((optionValueId) => {
        const simulatedVariant = this.#simulateOptionValueSelection({
          optionTypeId,
          optionValueId,
        });

        this.#updateAvailability({
          optionValuesOutlet,
          optionValueId,
          simulatedVariant,
        });

        this.#updatePrice({
          optionValuesOutlet,
          optionValueId,
          selectedVariant,
          simulatedVariant,
        });
      });
    });
  }

  get #productComponentOutlet() {
    return this.pagesProductComponentProductComponentOutlet;
  }

  get #optionValuesOutlets() {
    return this
      .containersProductsOptionTypesSelectorOptionValuesComponentOptionValuesOutlets;
  }

  #selectedOptionValueMap() {
    const selectedOptionValueMap = {};
    this.#optionValuesOutlets.forEach((optionValuesOutlet) => {
      selectedOptionValueMap[optionValuesOutlet.optionTypeId] =
        optionValuesOutlet.selectedOptionValue;
    });

    return selectedOptionValueMap;
  }

  #selectedOptionValues() {
    return new OptionValueCollection(
      Object.values(this.#selectedOptionValueMap()),
    );
  }

  #simulateOptionValueSelection({ optionTypeId, optionValueId }) {
    const optionValuesMap = { ...this.#selectedOptionValueMap() };
    optionValuesMap[optionTypeId] = optionValueId;

    const simulatedOptionValues = new OptionValueCollection(
      Object.values(optionValuesMap),
    );
    return (
      this.#productComponentOutlet.findVariantByOptionValues(
        simulatedOptionValues,
      ) || null
    );
  }

  #updateAvailability({ optionValuesOutlet, optionValueId, simulatedVariant }) {
    if (
      simulatedVariant == null ||
      simulatedVariant.availability == 'out_of_stock'
    ) {
      optionValuesOutlet.setAvailability({ optionValueId, available: false });
    } else {
      optionValuesOutlet.setAvailability({ optionValueId, available: true });
    }
  }

  #updatePrice({
    optionValuesOutlet,
    optionValueId,
    selectedVariant,
    simulatedVariant,
  }) {
    if (selectedVariant?.price && simulatedVariant?.price) {
      const priceDifference = simulatedVariant.price - selectedVariant.price;

      optionValuesOutlet.setPriceDifference({
        optionValueId,
        priceDifference,
      });
    } else {
      optionValuesOutlet.resetPrice(optionValueId);
    }
  }
}
