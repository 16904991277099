import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['variantInput'];

  static outlets = ['pages--product-component--product-component'];

  variantSelected() {
    this.productComponentOutlet.setSelectedVariantIds(this.#selectedVariants());
  }

  get productComponentOutlet() {
    return this.pagesProductComponentProductComponentOutlet;
  }

  #selectedVariants() {
    return this.variantInputTargets.flatMap((variantInput) => {
      if (this.#isInputSelected(variantInput)) {
        return [variantInput.value];
      }
      return [];
    });
  }

  #isInputSelected(element) {
    return (
      (element.tagName === 'INPUT' && element.checked) ||
      element.tagName === 'SELECT'
    );
  }
}
