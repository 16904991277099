import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static classes = ['initial', 'transparent'];

  static outlets = [
    'containers--products--slices--selector-component--transparent-header',
  ];

  connect() {
    this.setHeaderAppearance();
  }

  setHeaderAppearance() {
    for (const classname of this.initialClasses) {
      this.element.classList.toggle(classname, !this.#isTransparent());
    }

    for (const classname of this.transparentClasses) {
      this.element.classList.toggle(classname, this.#isTransparent());
    }
  }

  #isTransparent() {
    return this.transparentSliceOutlets.some((outlet) =>
      this.#headerIsOverlapping(outlet.element),
    );
  }

  #headerIsOverlapping(targetElement) {
    const rect1 = this.element.getBoundingClientRect();
    const rect2 = targetElement.getBoundingClientRect();

    return (
      rect1.left < rect2.right &&
      rect1.right > rect2.left &&
      rect1.top < rect2.bottom &&
      rect1.bottom > rect2.top
    );
  }

  get transparentSliceOutlets() {
    return this
      .containersProductsSlicesSelectorComponentTransparentHeaderOutlets;
  }
}
