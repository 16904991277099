import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['copyIcon', 'checkIcon'];
  static values = {
    shareMyLaptopUrl: String,
  };

  copyShareMyLaptopUrl() {
    navigator.clipboard.writeText(this.shareMyLaptopUrlValue);

    this.copyIconTarget.classList.add('hidden');
    this.checkIconTarget.classList.remove('hidden');
  }
}
