import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (this.element.getAttribute('loading') == 'lazy') {
      this.observer = new IntersectionObserver(this.intersect, {
        rootMargin: '0px 0px 2000px 0px',
      });
      this.observer.observe(this.element);
    }
  }

  disconnect() {
    this.observer?.disconnect();
  }

  intersect = (entries) => {
    const lastEntry = entries.slice(-1)[0];
    if (lastEntry?.isIntersecting) {
      this.observer.unobserve(this.element);
      this.element.setAttribute('loading', 'eager');
    }
  };
}
