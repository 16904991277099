import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['timer'];

  static values = {
    currentTimestamp: Number,
    endTimestamp: Number,
    message: String,
  };

  static classes = ['loading', 'message', 'timer'];

  async connect() {
    await this.initializeCountdown();
    this.startCountdown();
  }

  disconnect() {
    this.#clearCountdownInterval();
  }

  currentTimestampValueChanged() {
    if (this.currentTimestampValue === 0) {
      this.currentTimestampValue = Date.now();
    }

    if (this.#isTimerComplete()) {
      this.stopCountdown();
      return;
    }

    this.timerTarget.innerHTML = this.#formatTimeUntilString(
      this.currentTimestampValue,
      this.endTimestampValue,
    );
  }

  initializeCountdown() {
    return new Promise((resolve) => {
      window.requestAnimationFrame((ts) => {
        this.#animateCountdown(ts, resolve);
      });
    });
  }

  startCountdown() {
    this.element.classList.remove(this.loadingClass);
    this.countdownInterval = setInterval(() => {
      this.currentTimestampValue = Date.now();
    }, 1000);
  }

  stopCountdown() {
    if (this.hasMessageValue) {
      this.timerTarget.innerHTML = this.messageValue;
      this.element.classList.remove(this.timerClass);
      this.element.classList.add(...this.messageClasses);
    }

    this.element.classList.remove(this.loadingClass);

    this.#clearCountdownInterval();
  }

  /**
   * @returns {boolean} true if the current time is past the end timestamp value is
   */
  #isTimerComplete() {
    return Date.now() >= this.endTimestampValue;
  }

  /**
   * Clears the interval used to count down the timer each second.
   */
  #clearCountdownInterval() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    }
  }

  /**
   * Calculate and format a string of the amount of time left until a time.
   *
   * @param {number} startTimestamp The start target time to calculate the difference between.
   * @param {number} endTimestamp  The end target time to calculate the difference between.
   * @returns {string} A formatted string of DD : HH : MM : SS.
   */
  #formatTimeUntilString(startTimestamp, endTimestamp) {
    const difference = Math.max(endTimestamp - startTimestamp, 0);

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds]
      .map((component) => String(component).padStart(2, '0'))
      .join(' : ');
  }

  /**
   * Animates the countdown's digits up from 0 to the current remaining time left, based on the state of the current timestamp and the end timestamp values.
   *
   * @param {number} timestamp The timestamp to calculate the time left from.
   * @param {function} completeCallback A callback function to call when animation has completed.
   */
  #animateCountdown(timestamp, completeCallback) {
    if (this.start === undefined) {
      this.start = timestamp;
    }

    if (this.amountToAnimate === undefined) {
      this.amountToAnimate =
        this.endTimestampValue - this.currentTimestampValue;
      this.currentTimestampValue = this.endTimestampValue;
    }

    const elapsed = timestamp - this.start;

    if (this.currentTimestampValue > Date.now()) {
      const interpolatedTimestampDiff = Math.floor(
        this.#linear(elapsed, 1000, this.amountToAnimate),
      );
      this.currentTimestampValue =
        this.endTimestampValue - interpolatedTimestampDiff;

      window.requestAnimationFrame((ts) =>
        this.#animateCountdown(ts, completeCallback),
      );
    } else {
      this.currentTimestampValue = Date.now();
      completeCallback();
    }
  }

  /**
   * Provides linear interpolation value for animation.
   *
   * @param {number} elapsed The elapsed time of the animation in milliseconds.
   * @param {number} length The total duration of the animation in milliseconds.
   * @param {number} value The target value of the animation.
   * @returns {number} The interpolated value.
   */
  #linear(elapsed, length, value) {
    const dt = elapsed / (length - elapsed);
    return dt * value;
  }
}
