import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static outlets = [
    'containers--product-configurations--section-component--choice',
    'containers--product-configurations--section-component--multiple-choice',
    'containers--product-configurations--section-component--section',
    'containers--product-configurations--subsections-component--subsections',
    'containers--product-configurations--accordion-component--accordion',
  ];

  static targets = ['powerAdapterRegionSection'];

  static values = {
    autoCollapseSections: Boolean,
    autoScrollToSections: Boolean,
    disableSectionsUntilPreviousSectionCompleted: Boolean,
    expansionCardsSectionTitle: String,
    expansionCardsSectionUrl: String,
    selectAllSectionsMessage: String,
    selectEnoughExpansionCardsMessage: String,
    powerAdapterRegionName: String,
    powerAdapterName: String,
  };

  connect() {
    this.onEvent('selectionMade', (event) => this.handleSelection(event));
    this.registerAddToBagListener();
    this.registerSaveConfigurationListener();

    // setTimeout is necessary to allow the section JS connect function to run,
    // which sets the selected state of the section
    setTimeout(() => {
      // Enable all sections if any have a selection. This is necessary for server-side
      // errors because the error could be in any section.
      if (
        this.disableSectionsUntilPreviousSectionCompletedValue &&
        this.sectionOutlets.some((section) => section.selected())
      ) {
        this.sectionOutlets.forEach((section) => section.enableSection());
      }
    }, 500);
  }

  registerAddToBagListener() {
    this.getJsTargets('add-to-bag-button').forEach((target) =>
      target.addEventListener(
        'click',
        this.checkConfigurationErrors.bind(this),
      ),
    );
  }

  registerSaveConfigurationListener() {
    this.getJsTargets('save-configuration-button').forEach((target) =>
      target.addEventListener(
        'click',
        this.checkConfigurationErrors.bind(this),
      ),
    );
  }

  handleSelection(event) {
    const { accordionOutlet, choiceOutlet } = event.detail;

    this.hidePowerAdapterRegionIfNoPowerAdapter(accordionOutlet, choiceOutlet);

    if (this.autoCollapseSectionsValue) {
      accordionOutlet?.close();
    }

    if (this.disableSectionsUntilPreviousSectionCompletedValue) {
      this.enableNextSection();
    }

    this.openNextSection();
    if (this.autoScrollToSectionsValue) {
      this.scrollToNextSection();
    }
  }

  hidePowerAdapterRegionIfNoPowerAdapter(accordionOutlet, choiceOutlet) {
    if (
      accordionOutlet?.element.id ===
        'product-configurations-accordion-component-power-adapter' &&
      this.hasPowerAdapterRegionSectionTarget
    ) {
      if (this.powerAdapter180WChosen(choiceOutlet)) {
        this.powerAdapterRegionSectionOutlet.setVisibilityLock(false);
        this.powerAdapterRegionSectionOutlet.show(true);
      } else {
        const powerAdapterRegionSelection =
          this.powerAdapterRegionSectionTarget.querySelector('input:checked');
        if (powerAdapterRegionSelection) {
          powerAdapterRegionSelection.checked = false;
        }
        this.fireEvent('resetChoice', {
          sectionName: this.powerAdapterRegionNameValue,
          resetType: 'sectionHidden',
        });
        this.powerAdapterRegionSectionOutlet.setVisibilityLock(true);
        this.powerAdapterRegionSectionOutlet.hide();
      }
    }
  }

  powerAdapter180WChosen(choiceOutlet) {
    return choiceOutlet.selectedChoiceTarget?.textContent?.match(
      this.powerAdapterNameValue,
    );
  }

  checkConfigurationErrors(submitEvent) {
    this.validateExpansionCardSection(submitEvent);
    this.validateSingleChoiceSections(submitEvent);
  }

  openNextSection() {
    this.getNextUnselectedSection()?.openSection();
  }

  enableNextSection() {
    this.getNextUnselectedSection()?.enableSection();
  }

  sectionOutletUnselected(sectionOutlet) {
    return (
      !sectionOutlet?.selected() &&
      !sectionOutlet?.element?.classList?.contains('hidden')
    );
  }

  scrollToNextSection() {
    const sectionOutlet = this.getNextUnselectedSection();
    if (sectionOutlet == null) return;

    const animationWait = this.autoCollapseSectionsValue ? 500 : 200;
    setTimeout(() => sectionOutlet.scrollTo(), animationWait);
  }

  getNextUnselectedSection() {
    return this.getUnselectedSections()[0];
  }

  getUnselectedSections() {
    return this.sectionOutlets.filter((outlet) =>
      this.sectionOutletUnselected(outlet),
    );
  }

  validateExpansionCardSection(submitEvent) {
    const invalidExpansionCardSection = this.sectionOutlets.find((section) => {
      if (section.multipleChoiceOutlet === undefined) return false;

      const { multipleChoiceOutlet } = section;
      return (
        multipleChoiceOutlet.isExpansionCards &&
        !multipleChoiceOutlet.isSelectedQuantityEnough
      );
    });
    if (invalidExpansionCardSection === undefined) return;

    invalidExpansionCardSection.multipleChoiceOutlet.setMissingSelectionState();
    this.showSnackbar({
      message: this.selectEnoughExpansionCardsMessageValue,
      messageType: 'alert',
      linkText: this.expansionCardsSectionTitleValue,
      linkUrl: this.expansionCardsSectionUrlValue,
    });
    submitEvent.preventDefault();
  }

  validateSingleChoiceSections(submitEvent) {
    const invalidSections = this.getUnselectedSections().filter(
      (section) => section.singleChoiceOutlet !== undefined,
    );
    if (invalidSections.length === 0) return;

    invalidSections.forEach((section) =>
      section.singleChoiceOutlet.setMissingSelectionState(),
    );
    submitEvent.preventDefault();
    if (invalidSections.length === 1) {
      invalidSections[0].singleChoiceOutlet.showUnselectedSectionMessage();
    } else {
      this.showSnackbar({
        message: this.selectAllSectionsMessageValue,
        messageType: 'alert',
      });
    }
  }

  get powerAdapterRegionSectionOutlet() {
    return this.sectionOutlets.find(
      (outlet) => outlet.nameValue === 'power-adapter-region',
    );
  }

  get sectionOutlets() {
    return this.containersProductConfigurationsSectionComponentSectionOutlets;
  }
}
